<div [ngClass]="{
  'dialog-action-buttons': true,
  'no-spacing': (spacing === false)
}">
  <div fxLayoutAlign="end">
    <div fxLayout="row-reverse" fxLayoutGap="10px" class="buttons">
      <button
        *ngIf="confirmButtonClickAction !== undefined"
        type="button"
        mat-flat-button
        class="mat-flat-button {{ confirmButtonType }}-confirm-button"
        attr.aria-labelledby="{{ ariaPrefix + 'confirm' | translate }}"
        (click)="performConfirmButtonClickAction()"
      >{{ buttonsLabelSection + '.' + confirmButtonLabel | translate}}</button>
      <button
        *ngIf="cancelButtonClickAction !== undefined"
        type="button"
        mat-button
        class="button-abort"
        attr.aria-labelledby="{{ ariaPrefix + 'cancel' | translate }}"
        (click)="performCancelButtonClickAction()"
      >{{ buttonsLabelSection + '.cancel' | translate}}</button>
    </div>
  </div>
  <!--Hidden Div's for a11y-Descriptions-->
  <div class="visually-hidden">
    <div
      *ngIf="cancelButtonClickAction !== undefined"
      id="{{ ariaPrefix + 'cancel' | translate }}"
    >
      {{ buttonsLabelSection + '.a11y-cancel' | translate }}
    </div>
    <div
      *ngIf="confirmButtonClickAction !== undefined"
      id="{{ ariaPrefix + 'confirm' | translate }}"
    >
      {{ buttonsLabelSection + '.a11y-' + confirmButtonLabel | translate }}
    </div>
  </div>
</div>

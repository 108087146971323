<mat-form-field class="input-block" [ngClass]="{'no-field-hint': !checkStrength}" appearance="outline">
  <mat-label>{{ (isNew ? 'password.new-password' : 'password.password') | translate }}</mat-label>
  <input minlength="8" matInput type="password" #passwordInput [type]="hidePw ? 'password' : 'text'" [(ngModel)]="password"
         [formControl]="passwordFormControl" [errorStateMatcher]="matcher"
         autocomplete="{{isNew ? 'new-password' : 'current-password'}}"
         (input)="checkInput()"/>
  <button *ngIf="showPwButton || isNew" mat-icon-button matSuffix type="button" (click)="hidePw = !hidePw" [matTooltip]="(hidePw ? 'password.show-password' : 'password.hide-password') | translate">
    <mat-icon>{{hidePw ? 'visibility_off' : 'visibility'}}</mat-icon>
  </button>
  <mat-error *ngIf="checkStrength && passwordFormControl.hasError('required')">
    {{ 'password.password-required' | translate }}
  </mat-error>
  <mat-error fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" *ngIf="checkStrength && !passwordFormControl.hasError('required')
             && passwordFormControl.hasError('minlength')">
    <mat-icon>do_not_disturb_on</mat-icon>
    <span>
      {{ 'password.password-too-short' | translate }}
    </span>
  </mat-error>
  <mat-hint fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="warn"
            *ngIf="checkStrength && passwordFormControl.valid && strength === strengthLevels.WEAK">
    <mat-icon>do_not_disturb_on</mat-icon>
    <span>
      {{ 'password.password-too-weak' | translate }}
    </span>
  </mat-hint>
  <mat-hint fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="warn-soft"
            *ngIf="checkStrength && passwordFormControl.valid && strength >= strengthLevels.OKAY && strength < strengthLevels.STRONG">
    <mat-icon>check_circle</mat-icon>
    <span>
      {{ 'password.password-okay' | translate }}
    </span>
  </mat-hint>
  <mat-hint fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="success"
            *ngIf="checkStrength && passwordFormControl.valid && strength >= strengthLevels.STRONG">
    <mat-icon>verified</mat-icon>
    <span>
      {{ 'password.password-strong' | translate }}
    </span>
  </mat-hint>
</mat-form-field>

import { AfterContentInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '../../../services/util/dialog.service';
import { GlobalStorageService, STORAGE_KEYS } from '../../../services/util/global-storage.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements AfterContentInit {

  constructor(
    private dialogService: DialogService,
    private globalStorageService: GlobalStorageService,
    private route: ActivatedRoute
  ){}

  ngAfterContentInit() {}

}

<div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px" fxFill>
  <div fxLayout="row" fxLayoutAlign="center">
    <mat-card>
      <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
      <div *ngIf="!isLoading">
        <div tabindex="-1" class="visually-hidden" id="message-button">
          {{ 'statistic.a11y-message-overview' | a11yIntro | async }}
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between">
          <mat-form-field appearance="outline" class="no-field-hint">
            <mat-label>{{ 'statistic.select-content-group' | translate }}</mat-label>
            <mat-select [(ngModel)]="currentGroup">
              <mat-option *ngFor="let contentGroup of contentGroups;" (click)="updateCollection()" [value]="contentGroup">
                {{ contentGroup.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-stroked-button (click)="showDeleteDialog()"
                  aria-labelledby="delete-answers">
            {{ 'content.delete-answers' | translate }}
          </button>
        </div>
        <app-list-statistic [contentGroup]="currentGroup"></app-list-statistic>
      </div>
    </mat-card>
  </div>
</div>

<div class="visually-hidden">
  <div id="delete-answers">{{ 'content.a11y-delete-all-answers' | translate }}</div>
</div>

<div>
  <h2 tabindex="0" mat-dialog-title>{{ 'overlay.title' | translate }} </h2>

  <mat-dialog-content tabindex="0">
    <p>{{ 'overlay.message' | translate }}</p>
  </mat-dialog-content>

  <app-dialog-action-buttons
    buttonsLabelSection="overlay"
    confirmButtonLabel="cancel"
    [confirmButtonType]=confirmButtonType
    [confirmButtonClickAction]="buildDeclineActionCallback()"
  ></app-dialog-action-buttons>
</div>

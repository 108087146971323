<div fxLayout="column" class="room-container">
  <div fxLayout="row" fxFill>
    <mat-drawer-container>
      <mat-drawer mode="side" opened [disableClose]="true">
        <div fxLayout="column" fxLayoutAlign="space-between" fxFill>
          <lib-extension-point *ngIf="isCreator" extensionId="remote"></lib-extension-point>
          <lib-extension-point *ngIf="!isCreator" extensionId="participant-header" [extensionData]="{roomId: roomId}"></lib-extension-point>
          <app-nav-bar></app-nav-bar>
        </div>
      </mat-drawer>
      <mat-drawer-content fxLayout="column" fxLayoutAlign="space-between" id="routing-content" [ngClass]="{'scrollbar-bottom-margin': navBarExists}">
        <div class="routing-container">
          <router-outlet></router-outlet>
        </div>
        <app-footer class="footer"></app-footer>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>

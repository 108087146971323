<div fxLayout="column" [ngClass]="{'flashcard-container': format === ContentType.FLASHCARD}" fxLayoutAlign="space-between">
  <div *ngIf="[ContentType.SLIDE, ContentType.FLASHCARD].indexOf(format) > -1" tabindex="0"
       id="message-type-info-button" class="visually-hidden">
    {{ 'statistic.a11y-message-type-info' | translate: {index: index+1} }}
  </div>
  <div *ngIf="[ContentType.SLIDE, ContentType.FLASHCARD].indexOf(format) === -1">
    <div tabindex="0" id="message-button" class="visually-hidden">
      {{ (answerCount === 0 ? 'statistic.a11y-message-evaluation' : 'statistic.a11y-message-evaluation-answers')
      | translate: {index: index+1, answers: answerCount} }}
    </div>
    <div [attr.hidden]="isPresentation || null" fxLayout="row" fxLayoutAlign="space-between">
      <app-answer-count tabindex="-1" *ngIf="!isLoading" [count]="answerCount"></app-answer-count>
      <div fxLayout="row">
        <lib-extension-point *ngIf="multipleRounds && answersVisible" extensionId="round-selection" (extensionEvent)="changeRound($event)" [extensionData]="{round: roundsToDisplay}"></lib-extension-point>
        <div fxLayout="row" fxLayoutAlign="end" [ngClass]="{'fix-width': !survey}" fxLayoutGap="8px">
          <button mat-icon-button *ngIf="answersVisible && !survey && correctOptionsPublished" disableRipple (click)="toggleCorrect()"
                  matTooltip="{{ 'statistic.show-correct' | translate }}"
                  appHotkey="c" [appHotkeyAction]="HotkeyAction.CLICK" [appHotkeyDisabled]="!active"
                  appTrackInteraction="Correct content answer options visibility toggled" [appTrackName]="correctVisible ? 'on' : 'off'">
          <mat-icon class="icon-primary">playlist_add_check</mat-icon>
          </button>
          <button mat-icon-button *ngIf="answersVisible && format === ContentType.WORDCLOUD && !isParticipant" (click)="toggleWordcloudView()"
                  matTooltip="{{ (showWordcloudModeration ? 'statistic.show-wordcloud' : 'statistic.show-wordcloud-list') | translate }}">
          <mat-icon class="icon-primary">toc</mat-icon>
          </button>
          <button *ngIf="!useCustomFlipAction" id="show-chart-btn" mat-icon-button (click)="toggleAnswers()"
                  matTooltip="{{ (answersVisible ? 'statistic.hide-results' : 'statistic.show-results') | translate }}"
                  appHotkey=" " [appHotkeyAction]="HotkeyAction.CLICK" [appHotkeyDisabled]="!active"
                  appTrackInteraction="Content results visibility toggled" [appTrackName]="answersVisible ? 'on' : 'off'">
          <mat-icon class="icon-primary">insert_chart</mat-icon>
          </button>
          <button *ngIf="content.format !== ContentType.FLASHCARD && useCustomFlipAction" mat-icon-button
                    matTooltip="{{'answer.go-to-content' | translate}}" (click)="customFlipEvent.emit()" aria-labelledby="flip-back"
                    appHotkey=" " [appHotkeyAction]="HotkeyAction.CLICK" [appHotkeyDisabled]="!active">
              <mat-icon class="icon-primary">
                cached
              </mat-icon>
            </button>
        </div>
      </div>
    </div>
  </div>
  <app-rendered-text tabindex="0" [rawText]="content.body" [renderedText]="content.renderedBody"></app-rendered-text>
  <div *ngIf="format === ContentType.FLASHCARD && !isPresentation">
    <button mat-icon-button (click)="toggleAnswers()" [matTooltip]="(answersVisible ? 'statistic.hide-flashcard-back' :
    'statistic.show-flashcard-back') | translate">
      <mat-icon class="icon-primary">fact_check</mat-icon>
    </button>
  </div>
</div>
<lib-extension-point extensionId="attachment-list" [extensionData]="attachmentData"></lib-extension-point>
<div [ngClass]="{'presentation-space': isPresentation}" >
  <app-statistic-choice *ngIf="[ContentType.CHOICE, ContentType.BINARY].indexOf(format) !== -1"
                        [content]="content" [directShow]="directShow" (updateCounterEvent)="updateCounter($event)"
                        [isSurvey]="survey" [isPresentation]="isPresentation" [active]="active"></app-statistic-choice>
  <app-statistic-scale *ngIf="format === ContentType.SCALE"
                        [content]="content" [directShow]="directShow" (updateCounterEvent)="updateCounter($event)"
                        [isSurvey]="survey" [isPresentation]="isPresentation" [active]="active"></app-statistic-scale>
  <app-statistic-text *ngIf="format === ContentType.TEXT" [content]="content" [isPresentation]="isPresentation"
                      [directShow]="directShow" (updateCounterEvent)="updateCounter($event)"></app-statistic-text>
  <app-statistic-sort *ngIf="format === ContentType.SORT" [content]="content" [isPresentation]="isPresentation"
                      [directShow]="directShow" (updateCounterEvent)="updateCounter($event)" [active]="active"></app-statistic-sort>
  <app-statistic-wordcloud *ngIf="format === ContentType.WORDCLOUD" [content]="content" [showModeration]="showWordcloudModeration"
                           [directShow]="directShow" (updateCounterEvent)="updateCounter($event)"></app-statistic-wordcloud>
</div>
<div *ngIf="content.format === ContentType.FLASHCARD && answersVisible">
  <div class="divider-space"></div>
  <mat-divider></mat-divider>
  <div class="divider-space"></div>
  <app-rendered-text [rawText]="content.additionalText" [dynamic]="true" [markdownFeatureset]="flashcardMarkdownFeatures"></app-rendered-text>
</div>

<div fxLayout="column" fxLayoutAlign="center center" fxFill>
  <mat-card class="login-card" fxLayout="column">
    <p class="info" fxFlexAlign="start">{{ 'password-reset.set-new-password' | translate }}</p>
    <div fxLayout="row">
      <p>{{'password-reset.your-email' | translate}}{{ email }}</p>
    </div>
    <form class="full-width" fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="10px">
      <app-password-entry [checkStrength]="true" [isNew]="true"></app-password-entry>
      <mat-form-field class="input-block" appearance="outline">
        <mat-label>{{ 'password-reset.key' | translate }}</mat-label>
        <input matInput type="text" #key [formControl]="keyFormControl" [errorStateMatcher]="matcher"/>
        <mat-error *ngIf="keyFormControl.hasError('required')">
          {{ 'password-reset.key-required' | translate }}
        </mat-error>
      </mat-form-field>
      <div fxLayout="column" fxLayoutAlign="center center">
        <button mat-raised-button (click)="setNewPassword(key.value)" class="button-primary login-button" type="submit">
          {{ 'password-reset.set-new-password-button' | translate }}
        </button>
      </div>
    </form>
  </mat-card>
</div>

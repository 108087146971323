<div fxLayout="column" fxLayoutGap="8px">
  <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
  <div class="chart-container" *ngIf="!isLoading" [ngClass]="{ 'invisible': !answersVisible,
  'normal-chart': !isPresentation && answersVisible, 'presentation-chart': isPresentation && answersVisible}">
    <canvas class="chart" id="{{ chartId }}"></canvas>
  </div>
  <mat-list *ngIf="!isLoading">
    <mat-list-item *ngFor="let answer of content.options; let i = index" [ngClass]="{'correct': showCorrect,
                    'no-background': isPresentation, 'all-animations': !isPresentation}">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <p class="answer-label">{{i + 1}}</p>
        <app-rendered-text [rawText]="answer.label" [renderedText]="answer.renderedLabel"></app-rendered-text>
      </div>
    </mat-list-item>
  </mat-list>
</div>

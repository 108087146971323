export const schufa = {

  '--primary': '#ffc500',
  '--primary-variant': '#ffc500',

  '--secondary': '#748589',
  '--secondary-variant': '#748589',

  '--primary-logo': '#5e35b1',
  '--secondary-logo': '#2e7d32',
  '--logo-font': '#474747',

  '--warn': '#d22d2b',
  '--warn-soft': '#f18223',
  '--on-warn': '#FFFFFF',

  '--background': '#f5f5f5',
  '--surface': '#fdfdfd',
  '--dialog': '#fcfcfc',
  '--cancel': '#e0e0e0',
  '--alt-surface': '#eeeeee',
  '--alt-dialog': '#eeeeee',

  '--on-primary': '#ffffff',
  '--on-secondary': '#ffffff',
  '--on-background': '#212121',
  '--on-surface': '#212121',
  '--on-cancel': '#000000',

  '--table-a': '#e0e0e0',
  '--table-b': '#eeeeee',
  '--border': '#e0e0e0',
  '--unlocked': '#ecf7ed',

  '--green': '#43a047',
  '--green-light': '#e8f5e9',
  '--red': '#ff5722',
  '--red-light': '#ffccbc',
  '--yellow': '#fdd835',
  '--yellow-light': '#fff9c4',
  '--blue': '#3f51b5',
  '--grey': '#9e9e9e',
  '--grey-light': '#e0e0e0',
  '--black': '#212121',

  '--bar-blue': '#027DB9',
  '--bar-yellow': '#ffca28',
  '--bar-teal': '#4D8076',
  '--bar-red': '#e64a19',
  '--bar-purple': '#8E79AB',
  '--bar-brown': '#9f6b3f',
  '--bar-green': '#787B1D',
  '--bar-pink': '#EB0054',

  '--likert-strongly-agree': '#3f51b5',
  '--likert-agree': '#7986cb',
  '--likert-neither': '#e0e0e0',
  '--likert-disagree': '#ff8a65',
  '--likert-strongly-disagree': '#ff5722'
};

export const schufa_meta = {

  translation: {
    name: {
      en: 'Schufa',
      de: 'Schufa'
    },
    description: {
      en: '',
      de: ''
    }
  },
  order: 1,
  previewColor: 'background'

};

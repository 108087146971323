<div class="list info {{position}}" fxLayout="column" fxLayoutAlign="center center" [ngClass]="{'presentation-mode': isPresentation}">
  <div class="icon-container" fxLayout="column" fxLayoutGap="16px" *ngIf="mode === 'badge'">
    <mat-icon *ngFor="let barItem of barItems; let i = index" class="icon"
              matBadge="{{barItem.count > 0 ? barItem.count : null}}" matBadgeOverlap="false"
              matTooltip="{{ 'sidebar.' + barItem.name | translate}}">
      {{barItem.icon}}
    </mat-icon>
  </div>
  <div *ngIf="mode === 'pure'">
    <p *ngFor="let barItem of barItems; let i = index" class="count" matTooltip="{{ 'sidebar.' + barItem.name | translate}}">
      {{ barItem.count }}
    </p>
  </div>
</div>

<div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutAlign="center">
    <div *ngIf="!isPresentation" id="live-announcer-button" tabIndex="-1" class="visually-hidden">
      {{ 'comment-page.a11y-message' | a11yIntro | async }}
    </div>
    <app-comment-list *ngIf="!isPresentation" [isModerator]="isModeration" [auth]="auth"></app-comment-list>
    <div *ngIf="isPresentation" fxLayout="row" fxLayoutAlign="start">
      <div id="presentation-button" tabIndex="-1" class="visually-hidden">
        {{ 'presentation.a11y-comment-message' | translate }}
      </div>
      <div class="presentation-container">
        <app-present-comment fxFill [isPresentation]="true" [comment]="activeComment"></app-present-comment>
      </div>
      <div #commentList (scroll)="onScroll()" class="list-container">
        <app-comment-list [auth]="auth" [isPresentation]="true"
                          [activeComment]="activeComment" (updateActiveComment)="updateComment($event)"></app-comment-list>
      </div>
    </div>
  </div>
</div>

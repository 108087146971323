<div mat-dialog-content>
  <h3 tabindex="0" aria-labelledby="update-header">{{ (afterUpdate ? 'home-page.update-made' : 'home-page.update-available') | translate }}</h3>
  <app-loading-indicator *ngIf="isLoading" [size]="30"></app-loading-indicator>
  <div *ngIf="!isLoading">
    <div *ngIf="changes">
      <p *ngIf="newsUrl" tabindex="0">{{ 'home-page.see-news' | translate }}<a href="{{newsUrl}}">{{ 'home-page.news' | translate }}</a>.</p>
      <p>{{ 'home-page.important-changes' | translate }}</p>
      <ul tabindex="0">
        <li *ngFor="let change of changes">{{ change }}</li>
      </ul>
    </div>
    <p class="hot-fix" *ngIf="!changes" tabindex="0">{{ 'home-page.hot-fix-update' | translate }}</p>
  </div>
</div>
<div class="dialog-footer" fxLayoutAlign="end center" fxLayoutGap="10px">
  <app-loading-indicator *ngIf="!afterUpdate && !updateReady" size="24" height="auto"></app-loading-indicator>
  <button mat-flat-button class="{{ afterUpdate ? 'button-abort' : 'button-primary'}}"
          aria-labelledby="update-button" [disabled]="!afterUpdate && !updateReady" (click)="close()">
    {{ (afterUpdate ? 'dialog.close' : 'home-page.update') | translate }}</button>
</div>

<div class="visually-hidden">
  <div id="update-header">{{ 'home-page.a11y-update-' + (afterUpdate ? 'made' : 'available') | translate }}</div>
  <div id="update-button">{{ 'home-page.a11y-' + (afterUpdate ? 'close' : 'update') | translate }}</div>
</div>

<div fxLayout="column" fxLayoutAlign="center center" fxFill>
  <form (ngSubmit)="joinRoom(roomCode.value)">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <mat-form-field (keyup.enter)="onEnter(roomCode.value)" appearance="outline" class="no-field-hint big-input" floatLabel="always">
        <mat-label>{{ 'home-page.room-id' | translate }}</mat-label>
        <input id="room-id-input" matInput #roomCode
               type="text" inputmode="numeric"
               (beforeinput)="enforceLengthLimit($event)"
               (input)="handleInput($event)" [formControl]="roomCodeFormControl"
               [errorStateMatcher]="matcher" aria-labelledby="join-input-label" autocomplete="off"
               placeholder="{{'home-page.example' | translate }}{{'11223344' | splitShortId: false: true}}"
               appHotkey="1" [appHotkeyTitle]="'home-page.enter-room-number' | translate"
        />
        <button id="join-button" mat-icon-button class="mat-primary" type="submit" role="button"
                (click)="joinRoom(roomCode.value)" aria-labelledby="join-button-label" matSuffix>
            <mat-icon class="button-primary">keyboard_arrow_right</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </form>
</div>

<div class="visually-hidden">
  <div id="join-input-label">{{ inputA11yString | translate: {title: appTitle} }}</div>
  <div id="join-button-label">{{ 'home-page.a11y-join-button' | translate }}</div>
</div>

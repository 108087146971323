<div fxLayout="column" class="container">
  <div class="heading" fxLayout="column" fxLayoutAlign="center center">
    <lib-extension-point extensionId="app-logo" [extensionData]="{ 'height': '65px' }">
      <h1>
        <span class="main-heading-secondary" aria-hidden="true">{{ 'home-page.welcome' | translate }}</span>
        <span class="main-heading-primary">ARSnova</span>
      </h1>
    </lib-extension-point>
    <lib-extension-point extensionId="branding-title"></lib-extension-point>
  </div>
  <div fxLayout="row" fxLayoutAlign="center">
        <div fxLayout="row" fxLayoutAlign="center">
          <div fxLayout="column" fxLayoutAlign="center center" fxFill>
            <app-room-join [inputA11yString]="'home-page.a11y-welcome-message'" [appTitle]="appTitle"></app-room-join>
          </div>
        </div>
  </div>
  <lib-extension-point extensionId="home-info"></lib-extension-point>
</div>
<div class="visually-hidden">
  <button tabindex="-1" id="welcome-message">{{ 'home-page.a11y-welcome-message' | translate: {title: appTitle} }}</button>
</div>

<div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="20px">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'dialog.your-comment' | translate }}</mat-label>
    <textarea matInput
              #commentBody cdkTextareaAutosize cdkAutosizeMinRows=5 cdkAutosizeMaxRows=10 maxlength="500"
              [formControl]="bodyForm"></textarea>
    <mat-hint align="end"><span aria-hidden="true">{{commentBody.value.length}} / 500</span></mat-hint>
  </mat-form-field>
  <mat-form-field *ngIf="data.tags !== null && data.tags !== undefined && data.tags.length > 0" appearance="outline"
    class="no-field-hint">
    <mat-label aria-labelledby="category">{{ 'dialog.tag' | translate }}</mat-label>
    <mat-select class="comment-tag">
      <mat-option *ngFor="let tag of data.tags" value="{{tag}}" (click)="updateTag(tag)"
                  attr.aria-label="{{ 'dialog.a11y-select-tag' | translate: { tag: tag} }}">{{tag}}</mat-option>
      <mat-option (click)="updateTag('')">{{ 'dialog.none' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div>
  <lib-extension-point *ngIf="data.fileUploadEnabled" extensionId="image-upload" [extensionData]="eventsWrapper"></lib-extension-point>
  <app-dialog-action-buttons
    buttonsLabelSection="dialog"
    confirmButtonLabel="send"
    [cancelButtonClickAction]="buildCloseDialogActionCallback()"
    [confirmButtonClickAction]="buildCreateCommentActionCallback(commentBody)"
  ></app-dialog-action-buttons>
</div>

<div class="visually-hidden">
  <div id="category">{{ 'dialog.a11y-tag' | translate }}</div>
</div>

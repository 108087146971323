export class MembershipsChanged {
  type: string;
  payload: {
  };

  constructor() {
    this.type = 'MembershipsChanged';
    this.payload = {
    };
  }
}

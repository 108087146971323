export enum ContentType {
  CHOICE = 'CHOICE',
  BINARY = 'BINARY',
  SCALE = 'SCALE',
  TEXT = 'TEXT',
  SLIDE = 'SLIDE',
  SORT = 'SORT',
  FLASHCARD = 'FLASHCARD',
  WORDCLOUD = 'WORDCLOUD'
}

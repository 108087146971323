<footer class="footer">
    <div class="footer-outer">
    <div class="footer-inner">
        <div class="footer-slogan">Wir schaffen Vertrauen</div>

        <div class="footer-nav">
           <a [routerLink]="[]" [matMenuTriggerFor]="privacyMenu" class="link text">
              {{'footer.dsgvo' | translate}}</a>
        </div>
    </div>
    </div>
    <mat-menu #privacyMenu="matMenu" [overlapTrigger]="false">
    <button *ngIf="privacyUrl" class="item-title" mat-menu-item (click)="showDataProtection()">
      {{ 'footer.privacy-policy' | translate }}</button>
    <button class="item-title" mat-menu-item (click)="showCookieSettings()">
      {{ 'footer.cookie-settings' | translate }}</button>
    </mat-menu>
</footer>

<div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="16px">
  <button class="exitBtn" mat-icon-button (click)="close()" aria-labelledby="exit">
    <mat-icon class="exit-icon">arrow_back</mat-icon>
  </button>
  <div fxFill>
    <div fxLayout="row" fxLayoutAlign="center">
      <app-comment [comment]="comment" [inAnswerView]="true" [viewRoleInput]="data.role"></app-comment>
    </div>
    <div id="message-button" tabIndex="-1" class="visually-hidden">
      {{ 'comment-page.a11y-message-answer' | a11yIntro | async }}
    </div>
    <div fxLayout="row" fxLayoutAlign="center">
      <mat-card class="answer mat-elevation-z2" *ngIf="!isParticipant || answer">
        <div tabindex="-1" id="answer-text" class="visually-hidden" appHotkey="1">
          {{ 'comment-page.a11y-entered-answer' | translate: {answer: answer} }}
        </div>
        <div *ngIf="(isParticipant || !edit) && answer">
          <app-rendered-text [rawText]="answer" [dynamic]="true" [markdownFeatureset]="MarkdownFeatureset.SIMPLE">
          </app-rendered-text>
          <div fxLayout="row" fxLayoutAlign="end">
            <button mat-raised-button *ngIf="!isParticipant && !edit" class="button-primary" (click)="editAnswer()"
                    aria-labelledby="edit">
              <mat-icon class="edit-icon">edit</mat-icon>
              {{ 'comment-page.edit-answer' | translate}}
            </button>
          </div>
        </div>
        <div class="relative" *ngIf="!isParticipant && (edit || !answer)">
          <mat-tab-group animationDuration="0" [dynamicHeight]="true" (selectedTabChange)="tabChanged($event)">
            <mat-tab label="{{'comment-page.your-answer' | translate}}">
              <mat-form-field class="input-block" appearance="outline">
                <textarea #answerInput [(ngModel)]="answer" (focus)="edit = true"
                        matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="3"
                        cdkAutosizeMaxRows="8"
                        maxlength="500"
                        name="answer"
                        aria-labelledby="answer"
                        [placeholder]="'comment-page.enter-answer' | translate"
                        appHotkey="1">
                </textarea>
                <mat-hint align="end">
                  <span aria-hidden="true">
                  {{ answer ? answer.length : 0 }} / 500
                  </span>
                </mat-hint>
              </mat-form-field>
            </mat-tab>
            <mat-tab label="{{'room.preview' | translate}}" [disabled]="!answer">
              <div class="preview-container">
                <app-rendered-text [rawText]="answer" [dynamic]="renderPreview" [markdownFeatureset]="MarkdownFeatureset.SIMPLE">
                </app-rendered-text>
              </div>
            </mat-tab>
          </mat-tab-group>
          <app-formatting-toolbar *ngIf="!renderPreview" class="tab-group-formatting-bar" [inputElement]="answerInput" (valueChanged)="answer = $event"></app-formatting-toolbar>
        </div>
        <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
          <button mat-stroked-button *ngIf="!isParticipant && answer && edit" class="delete"
                  aria-labelledby="delete" (click)="openDeleteAnswerDialog()">
            {{'comment-page.delete-answer' | translate}}</button>
          <button mat-raised-button *ngIf="!isParticipant && (edit || !answer)" class="button-primary"
                  aria-labelledby="submit" (click)="saveAnswer()">
            {{'comment-page.save-answer' | translate}}</button>
        </div>
      </mat-card>
    </div>
  </div>
<div>

<div class="visually-hidden">
  <div id="answer">{{ 'comment-page.a11y-enter-answer' | translate }}</div>
  <div id="delete">{{ 'comment-page.a11y-delete-answer' | translate }}</div>
  <div id="submit">{{ 'comment-page.a11y-save-answer' | translate }}</div>
  <div id="edit">{{ 'comment-page.a11y-edit-answer' | translate }}</div>
  <div id="exit">{{ 'comment-page.a11y-close' | translate }}</div>
</div>

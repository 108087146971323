export enum RemoteMessage {
  UPDATE_COMMENT_STATE = 'UpdateCommentState',
  COMMENT_ID_CHANGED = 'CommentIdChanged',
  CONTENT_STATE_UPDATED = 'ContentStateUpdated',
  CONTENT_GROUP_UPDATED = 'ContentGroupUpdated',
  UI_STATE_CHANGED = 'UiStateChanged',
  SURVEY_STATE_CHANGED = 'SurveyStateChanged',
  CHANGE_CONTENTS_STATE = 'ChangeContentsState',
  CHANGE_COMMENTS_STATE = 'ChangeCommentsState',
  CHANGE_SURVEY_STATE = 'ChangeSurveyState',
  CHANGE_FEATURE_ROUTE = 'ChangeFeatureRoute',
  FOCUS_STATE_CHANGED = 'FocusStateChanged',
  TOGGLE_VISUALIZATION_UNIT = 'ToggleVisualizationUnit',
  TOGGLE_ANSWER_LIST_LAYOUT = 'ToggleAnswerListLayout'
}

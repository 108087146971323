<div fxLayout="column" fxLayoutAlign="center center" fxFill>
  <mat-card class="login-card" fxLayout="column" fxLayoutAlign="center center">
    <form (ngSubmit)="resetPassword()" class="full-width" fxLayout="column" fxLayoutAlign="space-around"
          fxLayoutGap="10px">
      <mat-form-field class="input-block" appearance="outline">
        <mat-label>{{ 'login.email' | translate }}</mat-label>
        <input matInput type="email" #email [(ngModel)]="username" [formControl]="usernameFormControl"
               [errorStateMatcher]="matcher" name="email"/>
      </mat-form-field>
      <p class="info">{{ 'password-reset.forgot-password' | translate }}</p>
      <div fxLayout="column" fxLayoutAlign="center center">
        <button mat-raised-button class="button-primary login-button" type="submit">
          {{ 'password-reset.reset-password' | translate }}
        </button>
      </div>
    </form>
  </mat-card>
</div>

<div fxLayout="column">
  <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
  <div class="chart-container" *ngIf="!isLoading"
       [ngClass]="{ 'invisible': !answersVisible, 'normal-chart': !isPresentation && answersVisible,
                    'presentation-chart': isPresentation && answersVisible, 'no-bottom-margin': showAnswersBelow}">
    <canvas class="chart" id="{{ chartId }}"></canvas>
  </div>
  <div *ngIf="!isLoading && isPresentation && showAnswersBelow" fxLayout="row" fxLayoutAlign="space-around">
    <p *ngFor="let a of options; let i = index" class="chartAnswerLabel" [ngClass]="{'not-correct': colorLabel && !checkIfCorrect(i)}">
      <app-rendered-text [rawText]="a.label" [renderedText]="a.renderedLabel"></app-rendered-text>
    </p>
    <p *ngIf="content.abstentionsAllowed" class="chartAnswerLabel abstention-label" [ngClass]="{'not-correct': colorLabel}">
        {{ 'statistic.abstentions' | translate }}</p>
  </div>
  <mat-list *ngIf="!isLoading && !showAnswersBelow" role="list">
    <mat-list-item role="listitem" [ngClass]="{'correct': colorLabel && checkIfCorrect(i), 'no-background': isPresentation,
                   'all-animations': !isPresentation}" tabindex="0"
                   attr.aria-label="{{ ('statistic.a11y-choice-evaluation' | translate: { count: data[roundsToDisplay][i], answer: a.label }) +
                 (isSurvey ? '' : ((checkIfCorrect(i) ?
                 'statistic.a11y-correct' : 'statistic.a11y-wrong') | translate)) }}"
                 *ngFor="let a of options; let i = index">
    <div fxFill fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <p class="answer-label">{{labelLetters[i]}}</p>
        <app-rendered-text [rawText]="a.label" [renderedText]="a.renderedLabel"></app-rendered-text>
      </div>
      <mat-icon *ngIf="!isSurvey" [ngClass]="{'show-correct-icon': colorLabel && checkIfCorrect(i)}"
                class="correct-icon">check</mat-icon>
      </div>
    </mat-list-item>
  </mat-list>
</div>
<div class="visually-hidden">
  <div id="show-correct">{{ 'statistic.a11y-answer-indicator' | translate }}</div>
  <div id="show-chart">{{ 'statistic.a11y-show-chart' | translate }}</div>
</div>

<mat-list>
  <mat-list-item *ngFor="let answer of answers; let i = index" tabindex="0" [ngClass]="{'moderation': isModerator && !isPresentation}"
   attr.aria-label="{{ 'statistic.a11y-text-evaluation' | translate:{ count: answer.count, answer: answer.answer } }}">
    <span class="answer">{{answer.answer}}</span>
    <span *ngIf="answer.count > 1" class="count">x {{answer.count}}</span>
    <button *ngIf="isModerator" mat-icon-button class="delete-button" (click)="addToModerationList(answer)"
            [matTooltip]="('statistic.' + (banMode ? 'ban' : 'delete') + '-answer') | translate">
      <mat-icon class="icon-warn">close</mat-icon>
    </button>
    <mat-divider *ngIf="i < answers.length -  1"></mat-divider>
  </mat-list-item>
</mat-list>
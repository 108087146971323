<div class="header-container">
  <h2 mat-dialog-title tabindex="0" id="cookie-header">{{ 'cookies.title' | translate }}</h2>
</div>

<mat-dialog-content tabindex="-1">
  <div>
    <p tabindex="0">
      {{ 'cookies.intro' | translate }}
      {{ 'cookies.intro-privacy1' | translate }}
      <a href="{{privacyUrl}}" target="_blank">{{ 'cookies.privacy-policy' | translate }}</a>
      {{ 'cookies.intro-privacy2' | translate }}
    </p>
    <div>
      <div class="cookie-cat" *ngFor="let category of categories">
        <mat-slide-toggle labelPosition="before" [(ngModel)]="category.consent" [disabled]="category.required">
          <div [attr.aria-label]="('cookies.a11y-' + category.id) | translate">
            {{ 'cookies.category-' + category.id | translate }}</div>
        </mat-slide-toggle>
        <div class="cookie-cat-desc">{{ 'cookies.category-' + category.id + '-desc' | translate }}</div>
      </div>
    </div>
  </div>
  <p>
    {{ 'cookies.update-later' | translate }}
  </p>

</mat-dialog-content>
<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="10px">
  <button mat-stroked-button (click)="acceptSelectedCookies()"
          [attr.aria-label]="'cookies.a11y-accept-selected' | translate:{}">
    {{ 'cookies.cancel' | translate }}</button>
  <button mat-flat-button class="button-primary" (click)="acceptAllCookies()"
          [attr.aria-label]="'cookies.a11y-accept' | translate">
    {{ 'cookies.accept' | translate }}</button>
</div>
